<template>
  <div class="m-0" ref="linkRemb">
    <Alert />
    <div class="titre-container rs-title-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr rs-fs-2000">
        Détail de la demande de remboursement
      </h1>
    </div>
    <div class="text-left rs-mb-15">
      <a
        href="#"
        @click="goPrevious($event)"
        class="fr-link p-0 color-pr fs-875 rs-mb-625"
        title="Retour aux declarations"
        ><i class="ri-arrow-left-line ml-1"></i>Retour aux déclarations</a
      >
    </div>
    <table class="table table-borderless bg-grey">
      <caption>
        Liste des déclarations
      </caption>
      <thead class="bg-grey">
        <tr class="bt-dark selected-declaration">
          <th scope="col" class="ml-2">Taxe / Impôt</th>
          <th scope="col">Période de référence</th>
          <th scope="col" class="text-right">Montant demandé</th>
          <th scope="col">
            Date de réception <br />
            de la demande
          </th>
          <th scope="col">
            Statut <br />
            de la demande
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-line mb-3 rs-table" :key="item.id">
          <td
            class="white-space-default td-table td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm"
          >
            {{ item.redevabilite }}
            <p
              class="siret"
              v-if="item.redevabilite.toLowerCase().includes('dsn')"
            >
              {{ $filters.sirenFormat(item.siret) }}
            </p>
            <Info
              href="#"
              cssClass="fr-fi-information-line info a-info"
              :data="iContext(item.contexte)"
              v-if="item.contexte !== null"
              :large="true"
            />
          </td>
          <td class="td-table td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm">
            {{
              item.periodeReference &&
              $filters.dateFormat(item.periodeReference.dateDebut)
            }}
            {{
              item.periodeReference && item.periodeReference.dateDebut
                ? '-'
                : ''
            }}
            {{
              item.periodeReference &&
              $filters.dateFormat(item.periodeReference.dateFin)
            }}
          </td>
          <td
            class="td-table td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm text-right"
          >
            <span class="sr-only">
              {{ $filters.numberSpacedRgaa(item.montantDemande) }}</span
            >
            <span aria-hidden="true">
              {{ $filters.numberSpaced(item.montantDemande) }}
            </span>
          </td>
          <td class="td-table td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm">
            {{ $filters.dateFormat(item.dateReceptionDemande) }}
          </td>
          <td class="td-table td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm">
            {{ item.statutLibelle }}
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <p class="tva-detail-header rs-fs-875">
        Décision du {{ $filters.dateFormat(item.dateDecision) }}
      </p>
    </div>
    <table class="table rs-table-2 decision-table">
      <caption>
        Détail de la demande de remboursement
      </caption>
      <tr>
        <td class="text-left td-table td-table-2">
          <p class="decision-table-libelle rs-libelle">Montant demandé</p>
          <p class="color-pr montant-demande">
            <span class="sr-only">
              {{ $filters.numberSpacedRgaa(item.montantDemande) }}</span
            >
            <span aria-hidden="true" class="decision-table-montant-demande">
              {{ $filters.numberSpaced(item.montantDemande) }}
            </span>
          </p>
        </td>
        <div class="line"></div>
        <td class="text-left td-table td-table-2">
          <p class="decision-table-libelle rs-libelle">
            Montant remboursé ou en cours <br />
            de remboursement
          </p>
          <p>
            <span class="sr-only">
              {{ $filters.numberSpacedRgaa(item.montantOrdonnance) }}</span
            >
            <span aria-hidden="true" class="decision-table-data">
              {{ $filters.numberSpaced(item.montantOrdonnance) }}</span
            >
          </p>
        </td>
        <td class="text-left td-table td-table-2">
          <p class="decision-table-libelle rs-libelle">
            Montant non-remboursé mais <br />
            compensant un débit de TVA
          </p>
          <p>
            <span class="sr-only">
              {{ $filters.numberSpacedRgaa(item.montantCompense) }}</span
            >
            <span aria-hidden="true" class="decision-table-data"
              >{{ $filters.numberSpaced(item.montantCompense) }}
            </span>
          </p>
        </td>
        <td class="text-left td-table td-table-2">
          <p class="decision-table-libelle rs-libelle">Montant rejeté</p>
          <p>
            <span class="sr-only">
              {{ $filters.numberSpacedRgaa(item.montantRejete) }}</span
            >
            <span aria-hidden="true" class="decision-table-data">{{
              $filters.numberSpaced(item.montantRejete)
            }}</span>
          </p>
        </td>
      </tr>
    </table>
    <p class="text-center text-gray mt-5 pb-4">
      Ces informations sont données à titre indicatif et ne sont pas
      nécessairement exhaustives.
    </p>
  </div>
</template>

<script>
import Alert from '../shared/Alert';
import { iContext } from '../utils/methodsUtils';
import { mapGetters, mapActions } from 'vuex';
import { SOURCE_PAGE_REMBOURSEMENT_DETAIL } from '../constantes';
import Info from '../shared/Info';

export default {
  components: {
    Info,
    Alert
  },
  data() {
    return {
      componentPath: this.$route.path,
      isShows: [],
      window: {
        width: 0,
        height: 0
      },
      item: { redevabilite: '', contexte: {} }
    };
  },
  computed: {
    ...mapGetters(['getRouteData'])
  },
  methods: {
    ...mapActions(['setRouteData']),
    goPrevious(event) {
      if (event) {
        event.preventDefault();
      }
      /*

          table: indique si on est sur le tableau déclarations ou remboursements
          retourDetail: indique que l'on revient d'une page détail
      */
      const routeData = {
        source: SOURCE_PAGE_REMBOURSEMENT_DETAIL,
        table: 'remboursements',
        retourDetail: true
      };
      this.setRouteDataAndPush(routeData, 'Déclarations');
    },
    iContext(context) {
      return iContext(context);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (this.getRouteData && this.getRouteData.item) {
      this.item = this.getRouteData.item;
      this.$refs.linkRemb.scrollIntoView({ behavior: 'smooth' });
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
th {
  padding: 0.75rem 0;
  vertical-align: middle;
  background-color: #f8f8f8 !important;
  border-bottom: 2px solid #6a6a6a !important;
  height: 53px;
}
tr {
  height: 53px;
  vertical-align: middle;
}
.selected-declaration {
  border-bottom: 2px solid;
}
.table-line {
  border: solid 1px #ddd;
  border-left: solid 3px #000091;
  height: 72px;
  align-items: center;
  font-size: 0.875rem;
  vertical-align: middle;
}
.tva-detail-header {
  text-align: left;
  margin-top: 4rem;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 760px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .td-table {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem !important;
    font-size: 14px;
    padding-top: 1rem !important;
  }

  .td-table:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .tr-table {
    border: 1px solid var(--g400);
    border-left-color: $base-color !important;
    border-left-width: initial;
  }

  .rs-table {
    height: 17rem !important;
  }
  .rs-table-small {
    height: 3.5rem !important;
  }
  .rs-fs-2000 {
    font-size: 2rem;
  }
  .rs-fs-875 {
    font-size: 0.875rem;
  }
  .rs-title-container {
    margin-top: 40px;
    margin-bottom: 24px;
    height: 120px;
  }
  .rs-mb-625 {
    margin-bottom: 0.625rem;
  }

  .rs-table-2 {
    height: 29rem !important;
  }

  .rs-libelle {
    margin-top: 0 !important;
  }

  .rs-mb-15 {
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 760px) {
  .td-table-2 {
    padding-bottom: 1rem;
  }

  .line {
    width: 1px;
    height: 57px;
    margin: 40px 67px 4px 43px;
    border: solid 1px #cecece;
  }
  .rs-libelle {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 760px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem;
    font-size: 14px;
    padding-top: 1rem;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td label {
    position: relative;
    top: 20%;
    left: -2%;
    width: 100%;
    white-space: nowrap;
  }

  /*
		Label the data  table -  - 1
		*/
  .td-table-1-1td:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 2
		*/
  .td-table-1-2td-periode-ref:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-periode-ref:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 3
		*/
  .td-table-1-2td-date-limite-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-date-limite-dpt:nth-of-type(2):before {
    content: 'Montant demandé';
    color: gray;
    font-size: 12px;
  }
  /*
		Label the data  table -  - 4
		*/
  .td-table-1-2td-date-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-date-dernier-dpt:nth-of-type(2):before {
    content: 'Date de réception de la demande';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 8
		*/
  .td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm:nth-of-type(3):before {
    content: 'Montant demandé';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm:nth-of-type(4):before {
    content: 'Date de réception de la demande';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-mt-dm-dt-reception-st-dm:nth-of-type(5):before {
    content: 'Statut de la demande';
    color: gray;
    font-size: 12px;
  }
}

a:focus {
  border-radius: 0;
  box-shadow: none;
}

.voir-form {
  box-shadow: none;
}
.decision-table-libelle {
  font-size: 13px;
  font-weight: 400;
  color: #6a6a6a;
  margin-bottom: 0;
  padding-bottom: 0;
}
.decision-table-montant-demande {
  font-size: 30px;
  font-weight: bold;
}
.decision-table-data {
  font-size: 16px;
  font-weight: bold;
  padding-top: 0;
}

.decision-table {
  border: 1px solid #e7e7e7;
  border-collapse: collapse;
  padding-bottom: 2.375rem;
}
</style>
